import { useEffect, useMemo, useState } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import { isEqual } from 'lodash';

import ContentTile from 'components/shared/content-tile';
import { SearchBar } from 'components/shared/search-bar';
import { useProductsPageState } from 'hooks/menu';
import useAnalytics from 'hooks/use-analytics';
import { MenuProductsProduct, MenuProductsResponseBody } from 'types/menu/api';
import { RegisterPrinter } from 'types/register-printing';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { ActionBar } from './action-bar';
import { BulkChangePricingModal } from './bulk-price-edit-modal';
import { CategoryFilters } from './category-filters';
import { useBulkEditContext } from './context';
import { CustomOutOfStockModal } from './custom-out-of-stock-modal';
import { FilterButton } from './filter-button';
import { FiltersModal, MenuProductsFilters } from './filters-modal';
import { ProductsTable } from './table';
import { TableSettings } from './table-settings';

import styles from './styles.module.scss';

type Props = {
  menu: MenuProductsResponseBody;
  printerSettings: RegisterPrinter[];
  shop: Shop;
};

const defaultMenuProductsFilters: MenuProductsFilters = {
  availability: [],
  categories: [],
  maxPrice: NaN,
  minPrice: NaN,
  printers: [],
  stock: [],
};

export const MenuProducts = ({ menu, shop, printerSettings }: Props) => {
  const { trackSearchedMenuItems, trackTableFiltersClicked } = useAnalytics();

  const { search, setSearch } = useProductsPageState();
  const [shouldClearAllFilters, setShouldClearAllFilters] = useState(false);
  const [menuProductsFilters, setMenuProductsFilters] =
    useState<MenuProductsFilters>(defaultMenuProductsFilters);
  const hasActiveFilters = !isEqual(
    defaultMenuProductsFilters,
    menuProductsFilters,
  );
  const { selectedProductIds, clearSelectedProductIds } = useBulkEditContext();
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    price: true,
    category: true,
    stock: true,
    printing: getShopHasFullRegister(shop) ? true : false,
    availability: getShopHasFullRegister(shop) ? true : false,
  });

  const [isCustomOutOfStockModalOpen, setIsCustomOutOfStockModalOpen] =
    useState(false);

  const [isBulkChangePricingModalOpen, setIsBulkChangePricingModalOpen] =
    useState(false);

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  const toggleCustomOutOfStockModal = () => {
    setIsCustomOutOfStockModalOpen(!isCustomOutOfStockModalOpen);
  };

  const closeBulkChangePricingModal = () => {
    setIsBulkChangePricingModalOpen(false);
  };

  const openBulkChangePricingModal = () => {
    setIsBulkChangePricingModalOpen(true);
  };

  const closeFiltersModal = () => {
    setIsFiltersModalOpen(false);
  };

  const openFiltersModal = () => {
    setIsFiltersModalOpen(true);
    trackTableFiltersClicked({ shopId: shop.shopId });
  };

  const selectedProductNames: string[] = useMemo(() => {
    const names: string[] = [];

    for (const product of menu.products) {
      if (selectedProductIds.has(product.id)) {
        names.push(product.name);
      }
    }

    return names;
  }, [menu.products, selectedProductIds]);

  const isRegister = getShopHasFullRegister(shop) ?? false;

  const filteredProducts = useMemo(() => {
    if (search) {
      const target = search.toLowerCase();
      const products: MenuProductsProduct[] = menu.products.filter(
        (product) =>
          product.name?.toLowerCase().includes(target) ||
          product.description?.toLowerCase().includes(target) ||
          product.types.find((it) => it.name.toLowerCase() === target),
      );

      trackSearchedMenuItems({
        countResults: products.length,
        isRegister: isRegister,
        page: 'menu items',
        searchText: search,
        shopId: shop.shopId,
      });

      return products;
    }

    return menu.products;
  }, [isRegister, menu.products, search, shop.shopId, trackSearchedMenuItems]);

  useEffect(() => {
    clearSelectedProductIds();
  }, [clearSelectedProductIds, search, categoryFilters, menuProductsFilters]);

  const hasEmptySearchResults = filteredProducts.length === 0 && search;
  const totalItems = menu.products.length ?? 0;

  return (
    <ContentTile
      dataTestId="menuItemsPageContentTile"
      className={styles.productContainer}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.search}>
          <SearchBar
            initialSearchValue={search}
            onChange={setSearch}
            placeholderText={`Search ${totalItems} items`}
          />
          <div className={styles.itemInfo}>
            <FilterButton
              icon="filter"
              onPress={openFiltersModal}
              hasActiveFilters={hasActiveFilters}
            >
              Filters
            </FilterButton>
            <TableSettings
              shop={shop}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
            />
          </div>
        </div>
        <CategoryFilters
          categories={menu.categories}
          categoryFilters={hasActiveFilters ? null : categoryFilters}
          setCategoryFilters={(value) => {
            setMenuProductsFilters(defaultMenuProductsFilters);
            setCategoryFilters(value);
            setShouldClearAllFilters(true);
          }}
        />
      </div>

      {hasEmptySearchResults ? (
        <div className={styles.noSearchResultsWrapper}>
          <div className={styles.noSearchResultsTitle}>
            {"Sorry, we couldn't find what you were looking for."}
          </div>
          <div className={styles.noSearchResultsBody}>
            No results for &quot;{search}&quot;. Check your spelling and try
            again, or search for something different.
          </div>
        </div>
      ) : (
        <ProductsTable
          products={filteredProducts}
          categories={menu.categories}
          shop={shop}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          menuProductsFilters={{
            ...menuProductsFilters,
            categories:
              hasActiveFilters &&
              menuProductsFilters.categories &&
              menuProductsFilters.categories.length > 0
                ? menuProductsFilters.categories
                : categoryFilters,
          }}
        />
      )}
      <ActionBar
        onPressCustom={toggleCustomOutOfStockModal}
        shop={shop}
        selectedProductNames={selectedProductNames}
        onOpenBulkChangePricingModal={openBulkChangePricingModal}
      />
      <CustomOutOfStockModal
        closeModal={toggleCustomOutOfStockModal}
        isOpen={isCustomOutOfStockModalOpen}
        shop={shop}
      />
      <BulkChangePricingModal
        isOpen={isBulkChangePricingModalOpen}
        closeModal={closeBulkChangePricingModal}
        shop={shop}
        products={menu.products}
      />
      <FiltersModal
        categories={menu.categories}
        isOpen={isFiltersModalOpen}
        closeModal={closeFiltersModal}
        printerSettings={printerSettings}
        shop={shop}
        shouldClearAllFilters={shouldClearAllFilters}
        setShouldClearAllFilters={setShouldClearAllFilters}
        onFiltersChange={(filters) => {
          setCategoryFilters([]);
          setMenuProductsFilters(filters);
        }}
      />
    </ContentTile>
  );
};
