/* eslint-disable @typescript-eslint/no-explicit-any */
import { FeedbackValue } from 'components/shared/feedback';
import { ResponseBusinessProfileAccountLocation } from 'hooks/business-profile/use-update-business-profile-location-mutation';
import { CalloutEventType } from 'types/callout';
import {
  CampaignManagerAnalyticsEventsName,
  ClickedCampaignManagerInsightsData,
  OpenedOptimizeMyCampaignModalData,
  ViewedCampaignManagerStateData,
} from 'types/campaign-manager';
import { CustomerList, CustomerLoyaltyStatus } from 'types/customers';
import {
  ClickedCustomerExportButtonEvent,
  ClickedCustomerImportButtonEvent,
  ViewedCustomerImportErrorDetailsEvent,
} from 'types/customers/profile/customer-profile';
import {
  ClickedCreateRegisterDiscount,
  CreatedRegisterDiscountAnalyticsData,
  DeactivatedRegisterDiscountAnalyticsData,
  EditedRegisterDiscountAnalyticsData,
  OnlineDiscountEvent,
  PromoCodeEvent,
  RegisterDiscountAnalyticsEventName,
} from 'types/discounts';
import {
  CreateReportAnalyticsData,
  DownloadReportAnalyticsData,
} from 'types/financials';
import { ClickedDownloadOnlineOrderPDFData } from 'types/financials/online-orders';
import {
  ClickedDownloadStatementIconData,
  DownloadedStatementDocData,
} from 'types/financials/statements';
import {
  HoursAnalyticsEventsName,
  LoadedDataHoursAnalyticsPage,
} from 'types/hours-analytics';
import { MenuCategory } from 'types/menu/category';
import { MenuProduct } from 'types/menu/product';
import {
  ClickedCreateAnOrderReportData,
  OrderFilterValue,
  OrdersAnalyticsEventsName,
} from 'types/orders';
import {
  ClickedRegisterSplashVideo,
  SubmittedRegisterUpsellForm,
} from 'types/register/register-splash';
import {
  ClickedOpenRegisterReceiptModalData,
  ClickedPrintReceiptModalData,
  RegisterOrdersAnalyticsEventsName,
  SelectedRegisterOrderStatusFilterData,
} from 'types/register-orders';
import { SalesforceAnalyticsData } from 'types/salesforce-upsell';
import {
  AnalyticsAdapter,
  ClickedExternalLinkData,
  ClickedInternalFeatureLinkData,
} from 'types/shared/analytics';
import { ClickedSupportDocLinkData } from 'types/shared/events';
import { Shop, ShopTraits } from 'types/shops';
import { Breakpoint, SiteEditorAnalyticsEventData } from 'types/site-editor';
import {
  ButtonConfigTouchedStatus,
  SliceBrandingTabLabels,
} from 'types/slice-ordering';
import {
  SmartButtonsClickedSaveAnalyticsData,
  SmartPopupsClickedSaveAnalyticsData,
  SubmittedShareCodeForm,
  WebsiteIntegrationsAnalyticsEventsName,
  WebsiteIntegrationsClickedBestPracticesAnalyticsData,
  WebsiteIntegrationsClickedClassicButtonsLinkAnalyticsData,
  WebsiteIntegrationsClickedEditorAnalyticsData,
  WebsiteIntegrationsClickedTechnicalHelpAnalyticsData,
  WebsiteIntegrationsCopiedScriptAnalyticsData,
  WebsiteIntegrationsFetchedSettingsAnalyticsData,
  WebsiteIntegrationsSubmitPreviewAnalyticsData,
  WebsiteIntegrationsUrlValidationErrorDisplayedAnalyticsData,
  WebsiteIntegrationsViewedGetCodeModalAnalyticsData,
} from 'types/website-tools';
import {
  analyticsEventSource,
  analyticsTab,
  viewMyMenuOnlineLinkLocations,
} from 'utilities/constants';
import { toDollarString } from 'utilities/currency';
import * as log from 'utilities/log';
import { snakeCaseKeys } from 'utilities/objects';

type TrackPageLoadData = {
  shopId: Shop['shopId'];
};

// TODO: Expand to include all page names sent with events, replacing existing string types
export const pageNames = {
  hoursAnalytics: 'hours analytics',
  menuItems: 'menu items',
  phone: 'phone',
  businessProfile: 'business profile',
} as const;
export type AnalyticsPageName = (typeof pageNames)[keyof typeof pageNames];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const events = (analytics: AnalyticsAdapter) => ({
  trackPageLoad: (
    name: string,
    { shopId, ...properties }: TrackPageLoadData,
  ) => {
    analytics.page(name, {
      shop_id: String(shopId),
      ...properties,
    });
  },
  identifyUser: (email: any, traits = {}) => {
    analytics.identify(email, traits);
  },

  trackClickedAddCustomizations: ({
    productId,
    shopId,
  }: {
    productId: any;
    shopId: any;
  }) => {
    analytics.track('clicked_add_customizations', {
      shop_id: shopId,
      product_id: productId,
    });
  },

  trackDashboardTileClick: (shopId: any, name: any) => {
    analytics.track('clicked_dashboard_tile', {
      shopId,
      name,
    });
  },

  trackExternalLinkClick: (data: ClickedExternalLinkData) => {
    analytics.track('clicked_external_link', {
      link_label: data.linkLabel,
      page: data.page,
      shopId: data.shopId,
      type: data.type,
    });
  },

  trackInternalFeatureLinkClick: (data: ClickedInternalFeatureLinkData) => {
    const { copy, fromPage, shopId, toPage } = data;

    analytics.track('clicked_internal_feature_link', {
      copy,
      from_page: fromPage,
      shop_id: String(shopId),
      to_page: toPage,
    });
  },

  trackNavigationItemClick: (
    shopId: Shop['shopId'],
    labels: string[],
    isExternalLink: boolean,
    isRegisterUser: boolean,
    registerMode: string,
  ) => {
    analytics.track('clicked_navigation_item', {
      shopId,
      nav_item_label: labels.at(-1),
      is_external_link: isExternalLink,
      click_level: String(labels.length),
      '1st_level_label': labels[0],
      '2nd_level_label': labels[1],
      isRegisterUser: isRegisterUser,
      registerMode: registerMode,
    });
  },

  trackPartnerButtonActions: (
    shopId: any,
    buttonClicked: any,
    color: any,
    size: any,
  ) => {
    analytics.track('partner_button_actions', {
      shopId,
      buttonClicked,
      color,
      size,
    });
  },

  trackDigitalOrdersDatePicker: (data: {
    shopId: string;
    startDate: string;
    endDate: string;
    label: string;
  }) => {
    analytics.track('digital_orders_date_picker', snakeCaseKeys(data));
  },

  trackDailyHoursActions: (
    shopId: any,
    buttonClicked: any,
    shippingType: any,
    date: any,
    editedDayTile: any,
    timeRange: any,
  ) => {
    analytics.track('daily_hours_actions', {
      shopId,
      button_clicked: buttonClicked,
      shipping_type: shippingType,
      date,
      edited_day_tile: editedDayTile,
      timeRange,
    });
  },

  trackSubmittedProductFeedbackSurvey: ({
    shopId,
    page,
    tile,
    copy,
    response,
  }: {
    shopId: number;
    page: AnalyticsPageName;
    tile: string;
    copy: string;
    response: FeedbackValue;
  }) => {
    analytics.track('submitted_product_feedback_survey', {
      shop_id: shopId,
      page,
      tile,
      copy,
      response,
    });
  },

  trackDownloadOrderStatement: (statementId: any) => {
    analytics.track('download_order_statement', {
      statementId,
    });
  },
  trackOrderPdfClick: (data: ClickedDownloadOnlineOrderPDFData) => {
    analytics.track('order_pdf_clicked', snakeCaseKeys(data));
  },
  trackCreateReportClick: (data: CreateReportAnalyticsData) => {
    analytics.track('clicked_create_report', snakeCaseKeys(data));
  },
  trackDownloadReport: (data: DownloadReportAnalyticsData) => {
    analytics.track('clicked_download_report', snakeCaseKeys(data));
  },
  trackUpdatedProductStock: (
    shopId: any,
    productId: any,
    productTypeId: any,
    duration: any,
    pathname: any,
  ) => {
    analytics.track('product_stock_updated', {
      shop_id: String(shopId),
      product_id: String(productId),
      product_type_id: productTypeId ? String(productTypeId) : null,
      new_availability: duration > 0 ? 'Out of stock' : 'In stock',
      time_duration_hours:
        duration === 0
          ? null
          : duration === Infinity
            ? 'Indefinite'
            : String(duration),
      page_name: pathname.includes(productId) ? 'edit item' : 'items',
    });
  },
  trackClickedProductStockDropdown: (
    shopId: any,
    productId: any,
    pathname: any,
  ) => {
    analytics.track('Clicked Product Stock Dropdown', {
      shop_id: String(shopId),
      page_name: pathname.includes(productId) ? 'edit item' : 'items',
    });
  },
  trackProductImageUpload: ({
    id,
    shopId,
    productImageFile,
    imageUrl,
    page,
    pageDetail,
  }: {
    id: any;
    shopId: any;
    productImageFile: any;
    imageUrl: any;
    page: string;
    pageDetail?: string;
  }) => {
    analytics.track('menu_product_image_uploaded', {
      product_id: id,
      shop_id: shopId,
      file_type: productImageFile.type,
      file_size_bytes: productImageFile.size,
      image_url: imageUrl,
      page: page,
      page_detail: pageDetail ?? '',
    });
  },

  trackProductImageRemoved: ({
    productId,
    shopId,
  }: {
    productId: any;
    shopId: any;
  }) => {
    analytics.track('menu_product_image_remove_clicked', {
      product_id: productId,
      shopId,
    });
  },
  trackClickedEditMenuProduct: ({
    shopId,
    productId,
    productName,
    productUnavailable,
  }: {
    shopId: any;
    productId: any;
    productName: any;
    productUnavailable: any;
  }) => {
    analytics.track('product_edit_clicked', {
      shop_id: shopId,
      product_id: productId,
      product_name: productName,
      product_unavailable: productUnavailable,
    });
  },
  trackValidationErrorDisplayed: ({
    shopId,
    location,
    input,
    value,
    errorType,
    errorMessage,
    submitCount,
  }: {
    shopId: any;
    location: any;
    input: any;
    value: any;
    errorType: any;
    errorMessage: any;
    submitCount: any;
  }) => {
    analytics.track('validation_error_displayed', {
      shop_id: shopId,
      location: location,
      input: input,
      value: value,
      error_type: errorType,
      error_message: errorMessage,
      submit_count: submitCount,
    });
  },
  trackProductUpdate: ({
    customizationEditContent,
    detailsEditContent,
    productId,
    productName,
    optionsEditContent,
    shopId,
    typeEditContent,
  }: {
    customizationEditContent: any;
    detailsEditContent: any;
    productId: any;
    productName: any;
    optionsEditContent: any;
    shopId: any;
    typeEditContent: any;
  }) => {
    analytics.track('menu_product_edit_sent', {
      shopId,
      ...snakeCaseKeys({
        customizationEditContent,
        detailsEditContent,
        productId,
        productName,
        optionsEditContent,
        typeEditContent,
      }),
    });
  },
  trackProductDelete: (
    shopId: Shop['shopId'],
    productId: MenuProduct['id'],
  ) => {
    analytics.track('track_product_deleted', {
      product_id: String(productId),
      shopId: String(shopId),
    });
  },
  trackSearchedMenuItems: (data: {
    countResults: number;
    isRegister: boolean;
    page: AnalyticsPageName;
    searchText: string;
    shopId: number;
  }) => {
    analytics.track('searched_menu_items', snakeCaseKeys(data));
  },
  trackMenuSelectedBulkEditItem: (data: {
    shopId: number;
    isRegister: boolean;
    page: AnalyticsPageName;
    productId: number;
    productName: string;
    isSelected: boolean;
  }) => {
    analytics.track('selected_item_to_bulk_edit', snakeCaseKeys(data));
  },
  trackMenuSelectedBulkEditCategory: (data: {
    shopId: number;
    isRegister: boolean;
    page: AnalyticsPageName;
    categoryId: number;
    categoryName: string;
    isSelected: boolean;
  }) => {
    analytics.track('selected_category_to_bulk_edit', snakeCaseKeys(data));
  },
  trackMenuClickedBulkEditInStock: (data: {
    shopId: number;
    isRegister: boolean;
    page: AnalyticsPageName;
    productIds: Set<number>;
    productNames: string[];
  }) => {
    analytics.track(
      'clicked_bulk_in_stock',
      snakeCaseKeys({
        ...data,
        productIds: Array.from(data.productIds),
      }),
    );
  },
  trackMenuClickedBulkEditOutOfStock: (data: {
    shopId: number;
    isRegister: boolean;
    page: AnalyticsPageName;
    optionSelected: 'custom' | '12 hours' | 'indefinitely';
    until: string | null;
    productIds: Set<number>;
    productNames: string[];
  }) => {
    analytics.track(
      'clicked_bulk_out_of_stock',
      snakeCaseKeys({
        ...data,
        productIds: Array.from(data.productIds),
      }),
    );
  },
  trackMenuCancelledBulkSelection: (data: {
    shopId: number;
    isRegister: boolean;
    page: AnalyticsPageName;
    countSelection: number;
  }) => {
    analytics.track('cancelled_bulk_selection', snakeCaseKeys(data));
  },
  trackMenuBulkEditDisplayedError: (data: {
    shopId: number;
    isRegister: boolean;
    page: AnalyticsPageName;
    failedItems: Set<number>;
    countFailedItems: number;
  }) => {
    analytics.track(
      'displayed_error',
      snakeCaseKeys({
        ...data,
        failedItems: Array.from(data.failedItems),
      }),
    );
  },
  trackProductChangeRequest: ({
    id,
    shopId,
    productRequestContent,
  }: {
    id: any;
    shopId: any;
    productRequestContent: any;
  }) => {
    analytics.track('menu_change_request_sent', {
      product_id: id,
      shopId,
      product_request_content: productRequestContent,
    });
  },

  trackShopScheduleUpdated: (shopId: any, success: any) => {
    const eventName = 'shop_schedule_updated';
    analytics.track(eventName, {
      shopId,
      success,
    });
  },
  trackMenuSortUpdated: (shopId: any, isCategory: any) => {
    analytics.track('track_menu_sort_updated', {
      shopId,
      itemType: isCategory ? 'category' : 'product',
    });
  },
  trackRegisterMenuSorted: (
    shopId: any,
    isCategory: any,
    isSingleColumn: any,
  ) => {
    analytics.track('ros_menu_sorted', {
      shopId,
      itemType: isCategory ? 'category' : 'product',
      view: isSingleColumn ? 'list' : 'grid',
    });
  },
  trackRegisterMenuColorsOpened: (
    shopId: any,
    isCategory: any,
    isSingleColumn: any,
  ) => {
    analytics.track('ros_menu_color_menu_opened', {
      shopId,
      itemType: isCategory ? 'category' : 'product',
      view: isSingleColumn ? 'list' : 'grid',
    });
  },
  trackRegisterMenuColorChanged: (
    shopId: any,
    color: any,
    isCategory: any,
    isSingleColumn: any,
  ) => {
    analytics.track('ros_menu_color_changed', {
      shopId,
      color,
      itemType: isCategory ? 'category' : 'product',
      view: isSingleColumn ? 'list' : 'grid',
    });
  },
  trackRegisterMenuViewToggled: (shopId: any, view: any) => {
    analytics.track('ros_menu_view_toggled', {
      shopId,
      view,
    });
  },
  trackRegisterMenuEditItemsClicked: (shopId: any, isSingleColumn: any) => {
    analytics.track('ros_menu_edit_items_clicked', {
      shopId,
      view: isSingleColumn ? 'list' : 'grid',
    });
  },
  trackPromoCodeCreate: (shopId: any, tag: any, success: any) => {
    analytics.track('slice_link_promo_code_created', {
      shopId,
      tag,
      success,
    });
  },
  trackPromoCodeDeactivate: (shopId: any, promoCodeId: any, success: any) => {
    analytics.track('slice_link_promo_code_deactivated', {
      shopId,
      id: promoCodeId,
      success,
    });
  },
  trackCreateRegisterDiscount: (
    data: CreatedRegisterDiscountAnalyticsData,
  ): void => {
    analytics.track(
      RegisterDiscountAnalyticsEventName.CreatedRegisterDiscount,
      data,
    );
  },
  trackEditRegisterDiscount: (
    data: EditedRegisterDiscountAnalyticsData,
  ): void => {
    analytics.track(
      RegisterDiscountAnalyticsEventName.EditedRegisterDiscount,
      data,
    );
  },
  trackDeleteRegisterDiscount: (
    data: DeactivatedRegisterDiscountAnalyticsData,
  ): void => {
    analytics.track(
      RegisterDiscountAnalyticsEventName.DeactivatedRegisterDiscount,
      data,
    );
  },
  trackCreateAutomaticDiscount: ({
    shopId,
    discountPercent,
  }: {
    shopId: any;
    discountPercent: any;
  }) => {
    analytics.track('slice_link_online_discount_created', {
      discountPercent,
      shopId,
    });
  },
  trackEditAutomaticDiscount: ({
    shopId,
    discountPercent,
  }: {
    shopId: any;
    discountPercent: any;
  }) => {
    analytics.track('slice_link_online_discount_edited', {
      discountPercent,
      shopId,
    });
  },
  trackDeleteAutomaticDiscount: ({ shopId }: { shopId: string }) => {
    analytics.track('slice_link_online_discount_deleted', {
      shopId,
    });
  },
  trackAddClosing: (shopId: any, closing: any, success: any) => {
    const { closureReason, startDate, endDate, shippingType } = closing;

    analytics.track('shop_closing_created', {
      shopId,
      closing_reason: closureReason,
      closing_dates: {
        start: startDate,
        end: endDate,
      },
      closing_shipping_type: shippingType,
      success,
      source: analyticsEventSource,
    });
  },
  trackUpdateClosing: (shopId: any, closing: any, success: any) => {
    const { id, closureReason, startDate, endDate, shippingType } = closing;

    analytics.track('shop_closing_updated', {
      shopId,
      closing_id: id,
      closing_reason: closureReason,
      closing_dates: {
        start: startDate,
        end: endDate,
      },
      closing_shipping_type: shippingType,
      success,
    });
  },
  trackDeleteClosing: (shopId: any, closing: any, success: any) => {
    const { id, reason, startDate, endDate, shippingType } = closing;

    analytics.track('shop_closing_delete', {
      shopId,
      closing_id: id,
      closing_reason: reason,
      closing_dates: {
        start: startDate,
        end: endDate,
      },
      closing_shipping_type: shippingType,
      success,
    });
  },
  trackShopScore: ({ shopId, traitName }: { shopId: any; traitName: any }) => {
    analytics.track('shop_score', {
      shopId,
      trait_name: traitName,
    });
  },
  trackClickedAddNewProduct: ({ shopId }: { shopId: any }) => {
    analytics.track('clicked_add_new_item', {
      shopId,
    });
  },
  trackMenuProductCreated: ({
    categoryId,
    isFeatured,
    productDescription,
    productName,
    productTypes,
    shopId,
  }: {
    categoryId: any;
    isFeatured: any;
    productDescription: any;
    productName: any;
    productTypes: any;
    shopId: any;
  }) => {
    analytics.track('menu_product_create', {
      categoryId,
      is_featured: isFeatured,
      product_description: productDescription,
      product_name: productName,
      product_types: productTypes,
      shopId,
    });
  },
  trackClickedDeleteCustomization: ({
    shopId,
    productId,
    customizationName,
  }: {
    shopId: any;
    productId: any;
    customizationName: any;
  }) => {
    analytics.track('clicked_delete_customization', {
      shopId,
      product_id: productId,
      customization_name: customizationName,
    });
  },
  trackSubmitReview: (shopId: Shop['shopId'], review: unknown) => {
    analytics.track('clicked_submit_customer_feedback_response', {
      shopId: String(shopId),
      review,
    });
  },

  trackViewFeedbackClicked: (shopId: Shop['shopId'], rowId: number) => {
    analytics.track('clicked_view_customer_feedback', {
      shopId: String(shopId),
      rowId,
    });
  },

  trackReplyFeedbackClicked: (shopId: Shop['shopId'], rowId: number) => {
    analytics.track('clicked_reply_to_customer_feedback', {
      shopId: String(shopId),
      rowId,
    });
  },

  trackCancelReplyButton: (shopId: Shop['shopId'], rowId: number) => {
    analytics.track('clicked_cancel_customer_feedback_response', {
      shopId: String(shopId),
      rowId,
    });
  },

  trackClickIntegrationItem: (name: any) => {
    analytics.track('clicked_integration_item', {
      name,
    });
  },

  trackCategorySearch: (shopId: any) => {
    analytics.track('categories_searched', {
      shopId,
    });
  },

  trackClickedAddNewCategory: ({ shopId }: { shopId: any }) => {
    analytics.track('clicked_add_new_menu_category', {
      shopId,
    });
  },

  trackCategoryUpdated: (shopId: any, categoryId: any) => {
    analytics.track('category_updated', {
      shopId,
      categoryId,
    });
  },

  trackCategoryCreated: (
    shopId: Shop['shopId'],
    categoryId: MenuCategory['id'],
  ) => {
    analytics.track('category_created', {
      shopId,
      categoryId,
    });
  },

  trackDeleteCategory: (shopId: any, id: any) => {
    analytics.track('category_deleted', {
      shopId,
      id,
    });
  },

  trackAddScheduledClosingTile: (shopId: any) => {
    analytics.track('add_scheduled_closing_tile_opened', {
      shopId,
    });
  },
  trackEditScheduledClosingTile: (shopId: any, closingId: any) => {
    analytics.track('edit_scheduled_closing_tile_toggled', {
      shopId,
      closingId,
    });
  },

  trackClickedViewMyMenuOnline: (
    shopId: any,
    viewMyMenuOnlineLinkLocation: any,
  ) => {
    if (
      !Object.values(viewMyMenuOnlineLinkLocations).includes(
        viewMyMenuOnlineLinkLocation,
      )
    ) {
      log.warn(
        'clicked_view_my_menu_online event was given an invalid link location: ' +
          viewMyMenuOnlineLinkLocation,
      );
      return;
    }

    const shopIdInteger = Number(shopId);

    if (!Number.isInteger(shopIdInteger) || shopIdInteger <= 0) {
      log.warn(
        'clicked_view_my_menu_online event was given an invalid shopId: ' +
          shopId,
      );
      return;
    }

    analytics.track('clicked_view_my_menu_online', {
      shopId,
      source: analyticsEventSource,
      linkLocation: viewMyMenuOnlineLinkLocation,
    });
  },
  trackAnalyticsOrderTypeFilterChanged: ({
    shopId,
    orderType,
  }: {
    shopId: any;
    orderType: any;
  }) => {
    analytics.track('track_analytics_order_type_filter_changed', {
      shopId,
      orderType,
    });
  },

  trackClickedAnalyticsTab: (shopId: any, tab: any) => {
    if (!Object.values(analyticsTab).includes(tab)) {
      log.warn(
        'clicked_analytics_tab was given an invalid tab identifier: ' + tab,
      );
      return;
    }

    const shopIdInteger = Number(shopId);

    if (!Number.isInteger(shopIdInteger) || shopIdInteger <= 0) {
      log.warn('clicked_analytics_tab was given an invalid shopId: ' + shopId);
      return;
    }

    if (tab === analyticsTab.orders) {
      analytics.track('clicked_analytics_tab', {
        shopId,
        viewedOrdersTab: true,
        viewedCustomersTab: false,
      });
    } else {
      analytics.track('clicked_analytics_tab', {
        shopId,
        viewedOrdersTab: false,
        viewedCustomersTab: true,
      });
    }
  },

  trackViewedVideoTutorial: ({
    shopId,
    type,
    page,
    videoType,
    videoLocation,
  }: {
    shopId: any;
    type: any;
    page: any;
    videoType: any;
    videoLocation: any;
  }) => {
    analytics.track('viewed_video_tutorial', {
      shopId,
      type,
      page,
      videoType,
      videoLocation,
    });
  },

  trackClickedCustomerListDropdown: (shopId: any, list: any) => {
    analytics.track('Clicked Customer List Dropdown', {
      shop_id: shopId,
      list: list.toLowerCase(),
    });
  },

  trackSelectedCustomerList: (
    shopId: any,
    previousList: any,
    listSelected: any,
  ) => {
    analytics.track('Selected Customer List', {
      shop_id: shopId,
      previous_list: previousList.toLowerCase(),
      list_selected: listSelected.toLowerCase(),
    });
  },

  trackSortedCustomerList: (shopId: any, sort: any) => {
    analytics.track('Sorted Customer List', {
      shop_id: shopId,
      column_sorted: sort.by,
      order: sort.direction,
    });
  },

  trackClickedCustomerTypeDropdown: (
    shopId: number,
    orderSource: string,
    page: string,
  ) => {
    analytics.track('Clicked Order Type Dropdown', {
      shop_id: String(shopId),
      order_source: orderSource.toLowerCase(),
      page,
    });
  },

  trackSelectedCustomerType: (
    shopId: string | Shop['shopId'],
    previousOrderType: string,
    selectedOrderType: string,
    page: string,
  ) => {
    analytics.track('Selected Order Type', {
      shop_id: String(shopId),
      previous_order_source: previousOrderType.toLowerCase(),
      selected_order_source: selectedOrderType.toLowerCase(),
      page,
    });
  },

  trackClickedDateRangeDropdown: (
    shopId: string,
    dateRange: string,
    page: string,
  ) => {
    analytics.track('Clicked Date Range Dropdown', {
      shop_id: shopId,
      date_range: dateRange.toLowerCase(),
      page,
    });
  },

  trackSelectedDateRange: (
    shopId: string,
    previousDateRange: string,
    selectedDateRange: string,
    page: string,
  ) => {
    analytics.track('Selected Date Range', {
      shop_id: shopId,
      previous_date_range: previousDateRange.toLowerCase(),
      selected_date_range: selectedDateRange.toLowerCase(),
      page,
    });
  },

  trackClickedSortByOnMobile: (shopId: any, sort: any) => {
    analytics.track('Clicked Sort By Customer List on Mobile', {
      shop_id: shopId,
      column_sorted: sort.by,
      order: sort.direction,
    });
  },

  trackClickedCustomerListTooltip: (shopId: any, list: any) => {
    analytics.track('Clicked Customer List Tooltip', {
      shop_id: shopId,
      list,
    });
  },

  trackSearchedCustomerName: (shopId: string, search: string) => {
    analytics.track('Searched Customer Name', {
      shop_id: shopId,
      search,
    });
  },

  trackClickedCustomerName: ({
    averageOrderValue,
    customerFullName,
    customerList,
    customerUuid,
    lastOrderedAt,
    loyaltyStatus,
    orderCount,
    orderTotal,
    shopId,
  }: {
    averageOrderValue: number;
    customerFullName: string;
    customerList: CustomerList;
    customerUuid: string;
    lastOrderedAt: string;
    loyaltyStatus: CustomerLoyaltyStatus;
    orderCount: number;
    orderTotal: number;
    shopId: string;
  }) => {
    analytics.track('Clicked Customer Name', {
      shop_id: shopId,
      customer_name: customerFullName,
      customer_uuid: customerUuid,
      customer_list: loyaltyStatus,
      last_order: lastOrderedAt,
      aov: Number(toDollarString(averageOrderValue)),
      order_count: orderCount,
      order_total: Number(toDollarString(orderTotal)),
      list: customerList.toLowerCase(),
    });
  },

  trackRemoveCustomerAnalyticsFilter: (
    shopId: string,
    filter: string,
    filterValue: string,
  ) => {
    analytics.track('Removed Customer Analytics Filter', {
      shop_id: shopId,
      filter,
      filter_value: filterValue.toLowerCase(),
    });
  },

  trackUpdatedCustomerAnalyticsLandingPage: (
    shopId: string,
    orderType: string,
    dateRange: string,
    allCustomerCount: number,
    newCustomerCount: number,
    repeatCustomerCount: number,
    loyalCustomerCount: number,
  ) => {
    analytics.track('Updated Customer Analytics Landing Page', {
      shop_id: shopId,
      order_source: orderType.toLowerCase(),
      date_range: dateRange.toLowerCase(),
      all_customer_count: allCustomerCount,
      new_customer_count: newCustomerCount,
      repeat_customer_count: repeatCustomerCount,
      loyal_customer_count: loyalCustomerCount,
    });
  },

  trackClickedViewList: (
    shopId: string,
    list: string,
    customerCount: number,
  ) => {
    analytics.track('Clicked View List', {
      shop_id: shopId,
      list: list.toLowerCase(),
      customer_count: customerCount,
    });
  },

  trackEditCustomerListShowRows: (
    shopId: any,
    previousRowsPerPage: any,
    selectedRowsPerPage: any,
  ) => {
    analytics.track('Edit Customer List Show Rows', {
      shop_id: shopId,
      previous_rows: previousRowsPerPage,
      new_rows: selectedRowsPerPage,
    });
  },

  trackChangedCustomerListPage: (
    shopId: any,
    previousPage: any,
    selectedPage: any,
  ) => {
    analytics.track('Changed Customer List Page', {
      shop_id: shopId,
      previous_page: previousPage,
      selected_page: selectedPage,
    });
  },

  trackUpdatedCustomerSegmentsPage: (
    shopId: string,
    list: string,
    orderType: string,
    dateRange: string,
    customerCount: number,
    favoriteItemProductId?: number,
    favoriteItemPhoto?: boolean,
  ) => {
    analytics.track('Updated Customer Segments Page', {
      shop_id: shopId,
      list: list.toLowerCase(),
      order_source: orderType.toLowerCase(),
      date_range: dateRange.toLowerCase(),
      customer_count: customerCount,
      favorite_item_product_id: favoriteItemProductId,
      favorite_item_photo: !!favoriteItemPhoto,
    });
  },

  trackClickedTop10CustomersTab: (
    shopId: string,
    tab: 'value' | 'frequency',
  ) => {
    analytics.track('clicked_top_10_tab', {
      shop_id: shopId,
      tab,
    });
  },

  trackClickedLoadMoreOrders: ({
    shopId,
    customerUuid,
  }: {
    shopId: string;
    customerUuid: string;
  }) => {
    analytics.track('clicked_load_more_orders', {
      shop_id: shopId,
      customer_uuid: customerUuid,
    });
  },

  trackClickedAddMenuPhoto: ({
    shopId,
    productId,
    productName,
    page,
    pageDetail,
  }: {
    shopId: string;
    productId: number;
    productName: string;
    page: string;
    pageDetail: string;
  }) => {
    analytics.track('clicked_add_menu_photo', {
      shop_id: shopId,
      product_id: productId,
      product_name: productName,
      page,
      page_detail: pageDetail,
    });
  },

  trackClickedSaveProductImage: ({
    shopId,
    productId,
  }: {
    shopId: string;
    productId: number;
  }) => {
    analytics.track('clicked_save_product_image_upload', {
      shop_id: shopId,
      product_id: productId,
    });
  },

  trackDisplayedPhotoValidationError: ({
    displayedErrorMessage,
    errorDetails,
    input,
    page,
    productId,
    shopId,
    fileType,
  }: {
    displayedErrorMessage: string;
    errorDetails: string[];
    input: string;
    page: string;
    productId: number;
    shopId: string;
    fileType: string | undefined;
  }) => {
    analytics.track('displayed_photo_validation_error', {
      displayed_error_message: displayedErrorMessage,
      error_details: errorDetails,
      input,
      page,
      product_id: productId,
      shop_id: shopId,
      file_type: fileType,
    });
  },

  trackGoToMenuItemsFromProductSalesTile: ({
    shopId,
    tile,
  }: {
    shopId: any;
    tile: any;
  }) => {
    analytics.track('clicked_go_to_menu_items_from_product_sales_tile', {
      shopId,
      tile,
    });
  },

  trackFilterProductSalesByCategory: ({
    shopId,
    selectedCategoryName,
    selectedCategoryId,
    tile,
  }: {
    shopId: any;
    selectedCategoryName: any;
    selectedCategoryId: any;
    tile: any;
  }) => {
    analytics.track('filtered_by_category_on_product_sales_analytics', {
      shopId,
      selectedCategoryName,
      selectedCategoryId,
      tile,
    });
  },

  trackCategoryNavigation: ({
    shopId,
    categoryNavType,
    categoryName,
    page,
  }: {
    shopId: any;
    categoryNavType: any;
    categoryName: any;
    page: any;
  }) => {
    analytics.track('Clicked Category Shortcut', {
      shop_id: shopId,
      category_nav_type: categoryNavType,
      category_name: categoryName,
      page: page,
    });
  },

  trackClickCloseShop: ({
    shopId,
    shippingType,
    currentStatus,
  }: {
    shopId: any;
    shippingType: any;
    currentStatus: any;
  }) => {
    analytics.track('Clicked Close Shop', {
      shop_id: shopId,
      service: shippingType,
      status: currentStatus,
    });
  },

  trackSelectedCloseShopService: ({
    shopId,
    shippingType,
  }: {
    shopId: any;
    shippingType: any;
  }) => {
    analytics.track('Selected Close Shop Service', {
      shop_id: shopId,
      service: shippingType,
    });
  },

  trackClickedReasonForClosing: ({ shopId }: { shopId: any }) => {
    analytics.track('Clicked Reason For Closing', {
      shop_id: shopId,
    });
  },

  trackSelectedReasonForClosing: ({
    shopId,
    reason,
    reasonId,
  }: {
    shopId: any;
    reason: any;
    reasonId: any;
  }) => {
    analytics.track('Selected Reason For Closing', {
      shop_id: shopId,
      reason,
      reason_id: reasonId,
    });
  },

  trackSubmitCloseShop: ({
    shopId,
    shippingType,
    reason,
    reasonId,
  }: {
    shopId: any;
    shippingType: any;
    reason: any;
    reasonId: any;
  }) => {
    analytics.track('Submitted Close Shop', {
      shop_id: shopId,
      service: shippingType,
      reason,
      reason_id: reasonId,
    });
  },

  trackClickOpenShop: ({
    shopId,
    shippingType,
    currentStatus,
  }: {
    shopId: any;
    shippingType: any;
    currentStatus: any;
  }) => {
    analytics.track('Clicked Open Shop', {
      shop_id: shopId,
      service: shippingType,
      status: currentStatus,
    });
  },

  trackClickPauseShop: ({
    shopId,
    shippingType,
  }: {
    shopId: any;
    shippingType: any;
  }) => {
    analytics.track('Clicked Pause Shop', {
      shop_id: shopId,
      service: shippingType,
    });
  },

  trackSelectedPauseShopService: ({
    shopId,
    shippingType,
  }: {
    shopId: any;
    shippingType: any;
  }) => {
    analytics.track('Selected Pause Shop Service', {
      shop_id: shopId,
      service: shippingType,
    });
  },

  trackClickedReasonForPausing: ({ shopId }: { shopId: any }) => {
    analytics.track('Clicked Reason For Pausing', {
      shop_id: shopId,
    });
  },

  trackSelectedReasonForPausing: ({
    shopId,
    reason,
    reasonId,
  }: {
    shopId: any;
    reason: any;
    reasonId: any;
  }) => {
    analytics.track('Selected Reason For Pausing', {
      shop_id: shopId,
      reason,
      reason_id: reasonId,
    });
  },

  trackClickedPauseDuration: ({ shopId }: { shopId: any }) => {
    analytics.track('Clicked Pause Duration', {
      shop_id: shopId,
    });
  },

  trackSelectedPauseDuration: ({
    shopId,
    duration,
  }: {
    shopId: any;
    duration: any;
  }) => {
    analytics.track('Selected Pause Duration', {
      shop_id: shopId,
      duration,
    });
  },

  trackSubmitPauseShop: ({
    shopId,
    shippingType,
    reason,
    reasonId,
    duration,
  }: {
    shopId: any;
    shippingType: any;
    reason: any;
    reasonId: any;
    duration: any;
  }) => {
    analytics.track('Submitted Pause Shop', {
      shop_id: shopId,
      service: shippingType,
      reason,
      reason_id: reasonId,
      duration,
    });
  },

  trackClickedExtendPause: ({
    shopId,
    shippingType,
    timeRemaining,
  }: {
    shopId: any;
    shippingType: any;
    timeRemaining: any;
  }) => {
    analytics.track('Clicked Extend Pause', {
      shop_id: shopId,
      service: shippingType,
      time_remaining: timeRemaining,
    });
  },

  trackSubmitExtendPause: ({
    shopId,
    shippingType,
    originalTimeRemaining,
    addedTime,
    newTimeReamaining,
  }: {
    shopId: any;
    shippingType: any;
    originalTimeRemaining: any;
    addedTime: any;
    newTimeReamaining: any;
  }) => {
    analytics.track('Submitted Extend Pause', {
      shop_id: shopId,
      service: shippingType,
      time_remaining: originalTimeRemaining,
      added_time: addedTime,
      new_time_remaining: newTimeReamaining,
    });
  },

  trackClickedLoadMoreDigitalAssets: ({
    shopId,
    category,
    subCategory,
    timesClicked,
  }: {
    shopId: any;
    category: any;
    subCategory: any;
    timesClicked: any;
  }) => {
    analytics.track('Clicked Load More Assets', {
      shop_id: shopId,
      category: category,
      sub_category: subCategory,
      times_clicked: timesClicked,
    });
  },

  trackClickedExpandDigitalAsset: ({
    shopId,
    category,
    subCategory,
    assetId,
    title,
    description,
    assetUrl,
  }: {
    shopId: any;
    category: any;
    subCategory: any;
    assetId: any;
    title: any;
    description: any;
    assetUrl: any;
  }) => {
    analytics.track('Clicked Expand Asset', {
      shop_id: shopId,
      category: category,
      sub_category: subCategory,
      asset_id: assetId,
      title: title,
      description: description,
      asset_url: assetUrl,
    });
  },

  trackClickedDownloadDigitalAsset: ({
    shopId,
    category,
    subCategory,
    assetId,
    title,
    description,
    page,
    assetUrl,
  }: {
    shopId: any;
    category: any;
    subCategory: any;
    assetId: any;
    title: any;
    description: any;
    page: any;
    assetUrl: any;
  }) => {
    analytics.track('Clicked Download Asset', {
      shop_id: shopId,
      category: category,
      sub_category: subCategory,
      asset_id: assetId,
      title: title,
      description: description,
      page: page,
      asset_url: assetUrl,
    });
  },

  trackClickedPartnerButtonTab: ({
    shopId,
    tab,
    previousTab,
  }: {
    shopId: string;
    tab: SliceBrandingTabLabels;
    previousTab: SliceBrandingTabLabels;
  }) => {
    analytics.track('clicked_partner_button_tab', {
      shop_id: shopId,
      tab,
      previous_tab: previousTab,
    });
  },

  trackClickedPartnerButtonType: ({
    shopId,
    type,
  }: {
    shopId: string;
    type: string;
  }) => {
    analytics.track('clicked_button_type', {
      shop_id: shopId,
      type,
    });
  },

  trackClickedCopyPartnerButtonHtml: ({
    shopId,
    type,
    color,
    hex,
    size,
    shape,
    letterCase,
  }: {
    shopId: string;
    type: string;
    color: string;
    hex: string;
    size: string;
    shape?: string;
    letterCase?: string;
  }) => {
    analytics.track('clicked_copy_html', {
      shop_id: shopId,
      type,
      color,
      hex,
      size,
      shape,
      case: letterCase,
    });
  },

  trackClickedCopyCustomLink: ({ shopId }: { shopId: string }) => {
    analytics.track('clicked_copy_custom_link', {
      shop_id: shopId,
    });
  },

  trackCustomizedButtonProperties: ({
    shopId,
    type,
    color,
    size,
    shape,
    letterCase,
  }: ButtonConfigTouchedStatus) => {
    analytics.track('customized_button_properties', {
      shop_id: shopId,
      type,
      color,
      size,
      shape,
      case: letterCase,
    });
  },

  // Employee permissions
  trackClickedRegisterPermissionsOn: (shopId: string) => {
    analytics.track('clicked_register_permissions_on', {
      shop_id: shopId,
    });
  },

  trackClickedAddUser: (shopId: string) => {
    analytics.track('clicked_add_user', {
      shop_id: shopId,
    });
  },

  trackEnteredUserInformation: ({
    shopId,
    userName,
    permissionGroups,
    email,
    phone,
  }: {
    shopId: string;
    userName: string;
    permissionGroups: string[];
    email: string | null;
    phone: string | null;
  }) => {
    analytics.track('entered_user_information', {
      shop_id: shopId,
      user_name: userName,
      permission_groups: permissionGroups,
      // We convert null or undefined to an empty string here to allow the event tracking to still see these fields
      // to allow for grouping user by no email for example.
      email: email ?? '',
      phone: phone ?? '',
    });
  },

  trackClickedGenerateNewPin: (shopId: string) => {
    analytics.track('clicked_generate_new_pin', {
      shop_id: shopId,
    });
  },

  trackClickedEditRegisterUserProfile: ({
    shopId,
    userName,
    userId,
  }: {
    shopId: string;
    userName: string;
    userId: string;
  }) => {
    analytics.track('clicked_edit_profile', {
      shop_id: shopId,
      user_name: userName,
      user_id: userId,
    });
  },

  trackClickedCheckOutBrandingStudio: (shopId: string) => {
    analytics.track('clicked_check_out_branding_studio_cta', {
      shop_id: shopId,
    });
  },

  trackClickedSocialMediaTitle: ({
    shopId,
    type,
  }: {
    shopId: string;
    type: string;
  }) => {
    analytics.track('clicked_social_media_tile', { type, shop_id: shopId });
  },

  // Custom Website
  trackUserViewedCustomWebsite: ({
    shopId,
    isActive,
    shopIntegrationAdoption,
  }: {
    shopId: string;
    isActive: boolean;
    shopIntegrationAdoption: ShopTraits['shopIntegrationAdoption'];
  }) => {
    analytics.track('viewed_custom_website', {
      shop_id: shopId,
      is_active: isActive,
      shop_integration_adoption: shopIntegrationAdoption,
    });
  },

  // User clicked the email link to initiate an email from create your website or site editor page
  trackClickedEmailLink: ({
    destination,
    page,
    shopId,
    subject,
    psmName,
  }: {
    destination: string;
    page: string;
    shopId: string;
    subject: string;
    psmName?: string;
  }) => {
    analytics.track('clicked_email_link', {
      destination,
      page,
      shop_id: shopId,
      subject,
      psm_name: psmName,
    });
  },

  // User toggled the iframe size using the devicy type toggles
  trackClickedViewportToggle: ({
    shopId,
    view,
    isActive,
  }: {
    shopId: string;
    view: Breakpoint;
    isActive: boolean;
  }) => {
    analytics.track('clicked_viewport_toggle', {
      shopId,
      view,
      is_active: isActive,
    });
  },

  // User saved and published settings in custom website and they were either successful or unsuccessful
  trackClickedSaveAndPublishCustomWebsite: (
    data: SiteEditorAnalyticsEventData,
  ) => {
    analytics.track('clicked_save_publish_custom_website', snakeCaseKeys(data));
  },

  // User clicked preview site CTA
  trackClickedPreviewSiteCta: (data: SiteEditorAnalyticsEventData) => {
    analytics.track('clicked_preview_site', snakeCaseKeys(data));
  },

  // When a shop opens the background color drawer and returns a true false if the user selected a color (independent of submitting).
  // Event should be created when the user clicks to open the background color drawer and should send when an intervening event is created.
  trackClickedBackgroundColorDrawer: ({
    selectedColor,
    shopId,
    isActive,
  }: {
    selectedColor: boolean;
    shopId: string;
    isActive: boolean;
  }) => {
    analytics.track('clicked_background_color_drawer', {
      selected_color: `${selectedColor}`,
      shop_id: shopId,
      is_active: isActive,
    });
  },

  // When a shop opens the font style drawer and returns a true false if the user selected a color (independent of submitting).
  // Event should be created when the user clicks to open the font style drawer and should send when an intervening event is created.
  trackClickedFontStyleDrawer: ({
    selectedFont,
    shopId,
    isActive,
  }: {
    selectedFont: boolean;
    shopId: string;
    isActive: boolean;
  }) => {
    analytics.track('clicked_font_style_drawer', {
      selected_font: `${selectedFont}`,
      shop_id: shopId,
      is_active: isActive,
    });
  },

  trackOptimizelyFlagDecision: ({
    shopId,
    flagKey,
    isFlagEnabled,
  }: {
    shopId: string;
    flagKey: string;
    isFlagEnabled: boolean;
  }): void => {
    analytics.track('optimizely_flag_decision', {
      shopId,
      flagKey,
      isFlagEnabled,
    });
  },

  trackClickedPsmContactInfo: ({
    shopId,
    page,
    psmName,
  }: {
    shopId: string;
    page: string;
    psmName: string;
  }): void => {
    analytics.track('clicked_psm_contact_info', {
      shop_id: shopId,
      page,
      psm_name: psmName,
    });
  },

  trackClickedCallLink: ({
    page,
    phoneNumber,
    psmName,
    shopId,
  }: {
    page: string;
    phoneNumber: string;
    psmName: string;
    shopId: string;
  }) => {
    analytics.track('clicked_call_link', {
      page,
      phone_number: phoneNumber,
      psm_name: psmName,
      shop_id: shopId,
    });
  },

  // Website integrations events
  trackClickedWebsiteIntegrationsEditorDropdown: (
    data: WebsiteIntegrationsClickedEditorAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ClickedEditorDropdown,
      snakeCaseKeys(data),
    );
  },

  trackSavedSmartButtonsSettings: (
    data: SmartButtonsClickedSaveAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ClickedSaveChanges,
      snakeCaseKeys(data),
    );
  },

  trackSavedSmartPopupsSettings: (
    data: SmartPopupsClickedSaveAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ClickedSaveChanges,
      snakeCaseKeys(data),
    );
  },

  trackSubmittedWebsiteIntegrationsWebsitePreview: (
    data: WebsiteIntegrationsSubmitPreviewAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.SubmitWebsitePreview,
      snakeCaseKeys(data),
    );
  },

  trackWebsiteIntegrationsUrlValidationErrorDisplayed: (
    data: WebsiteIntegrationsUrlValidationErrorDisplayedAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ValidationErrorDisplayed,
      snakeCaseKeys(data),
    );
  },

  trackViewedWebsiteIntegrationsCodeDocumentationModal: (
    data: WebsiteIntegrationsViewedGetCodeModalAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ViewedGetCodeModal,
      snakeCaseKeys(data),
    );
  },

  trackClickedCopyWebsiteIntegrationsCodeButton: (
    data: WebsiteIntegrationsCopiedScriptAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ClickedCopyCode,
      snakeCaseKeys(data),
    );
  },

  trackClickedWebsiteIntegrationsBestPracticesLink: (
    data: WebsiteIntegrationsClickedBestPracticesAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ClickedBestPractices,
      snakeCaseKeys(data),
    );
  },

  trackClickedTechnicalHelpEmailLink: (
    data: WebsiteIntegrationsClickedTechnicalHelpAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ClickedTechnicalHelpEmail,
      snakeCaseKeys(data),
    );
  },

  trackClickedClassicButtonsLinkToSmartButtons: (
    data: WebsiteIntegrationsClickedClassicButtonsLinkAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.ClickedInternalFeatureLink,
      snakeCaseKeys(data),
    );
  },

  trackFetchedWebsiteIntegrationsSettings: (
    data: WebsiteIntegrationsFetchedSettingsAnalyticsData,
  ) => {
    analytics.track(
      WebsiteIntegrationsAnalyticsEventsName.FetchedSettings,
      snakeCaseKeys(data),
    );
  },

  // Orders Page events
  trackSelectedOrderStatusFilter: <T extends OrderFilterValue>(
    data: SelectedRegisterOrderStatusFilterData<T>,
  ) => {
    analytics.track(
      RegisterOrdersAnalyticsEventsName.SelectedOrderStatusFilter,
      snakeCaseKeys(data),
    );
  },

  trackClickedCreateAReport: (data: ClickedCreateAnOrderReportData) => {
    analytics.track(
      OrdersAnalyticsEventsName.ClickedCreateAReport,
      snakeCaseKeys(data),
    );
  },

  trackClickedOpenRegisterReceiptModal: (
    data: ClickedOpenRegisterReceiptModalData,
  ) => {
    analytics.track(
      RegisterOrdersAnalyticsEventsName.ClickedOpenReceiptModal,
      snakeCaseKeys(data),
    );
  },

  trackClickedPrintReceiptModal: (data: ClickedPrintReceiptModalData) => {
    analytics.track(
      RegisterOrdersAnalyticsEventsName.ClickedPrintInReceiptModal,
      snakeCaseKeys(data),
    );
  },

  trackClickedVerifyAchEnrollmentBanner: (data: {
    linkLabel: 'verify';
    page: 'homepage' | 'statements' | 'orders' | 'reports';
    shopId: number;
    type: 'cta';
    url: string | null;
  }) => {
    analytics.track('opened_adyen_hop', snakeCaseKeys(data));
  },

  // Campaign Manager events
  trackViewedCampaignManagerState: (data: ViewedCampaignManagerStateData) => {
    analytics.track(
      CampaignManagerAnalyticsEventsName.ViewedCampaignManagerState,
      snakeCaseKeys(data),
    );
  },

  trackClickedCampaignManagerInsights: (
    data: ClickedCampaignManagerInsightsData,
  ) => {
    analytics.track(
      CampaignManagerAnalyticsEventsName.ClickedCampaignManagerInsights,
      snakeCaseKeys(data),
    );
  },

  trackOpenedOptimizeMyCampaignModal: (
    data: OpenedOptimizeMyCampaignModalData,
  ) => {
    analytics.track(
      CampaignManagerAnalyticsEventsName.OpenedOptimizeMyCampaignModal,
      snakeCaseKeys(data),
    );
  },

  // Hours Analytics events
  trackLoadedHoursAnalyticsPage: (data: LoadedDataHoursAnalyticsPage) => {
    analytics.track(
      HoursAnalyticsEventsName.LoadedHoursAnalyticsPage,
      snakeCaseKeys(data),
    );
  },

  // Salesforce Upsell events
  trackProductUpsellLinkClick: (data: SalesforceAnalyticsData) => {
    analytics.track('clicked_product_upsell_link', snakeCaseKeys(data));
  },

  // Shop Referral
  trackClickedPartnerReferralLink: ({
    linkLabel,
    page,
    referredFor,
    shopId,
    type,
  }: {
    linkLabel: string;
    page: string;
    referredFor: string;
    shopId: string;
    type: string;
  }) => {
    analytics.track('clicked_partner_referral_link', {
      link_label: linkLabel,
      page,
      referred_for: referredFor,
      shop_id: shopId,
      type,
    });
  },

  trackClickedRegisterSplashVideo: (data: ClickedRegisterSplashVideo) => {
    analytics.track('clicked_register_splash_video', snakeCaseKeys(data));
  },

  trackSubmittedRegisterUpsellForm: (data: SubmittedRegisterUpsellForm) => {
    analytics.track('submitted_register_upsell_form', snakeCaseKeys(data));
  },

  trackClickedSupportDocLink: (data: ClickedSupportDocLinkData) => {
    analytics.track('clicked_support_documentation_link', snakeCaseKeys(data));
  },

  trackClickedDownloadStatementIcon: (
    data: ClickedDownloadStatementIconData,
  ) => {
    analytics.track('clicked_download_on_statements', snakeCaseKeys(data));
  },

  trackDownloadedFromStatements: (data: DownloadedStatementDocData) => {
    analytics.track('downloaded_from_statements', snakeCaseKeys(data));
  },

  trackSubmittedShareCode: (data: SubmittedShareCodeForm) => {
    analytics.track('submitted_share_code', snakeCaseKeys(data));
  },

  trackViewedCallout: (data: CalloutEventType) => {
    analytics.track('viewed_callout', snakeCaseKeys(data));
  },

  trackDismissedCallout: (data: CalloutEventType) => {
    analytics.track('dismissed_callout', snakeCaseKeys(data));
  },

  trackClickedCalloutAction: (data: CalloutEventType) => {
    analytics.track('clicked_callout_action', snakeCaseKeys(data));
  },

  trackClickedCustomerExport: (data: ClickedCustomerExportButtonEvent) => {
    analytics.track(
      'clicked_export_button',
      snakeCaseKeys({
        customerType: data.customerType,
        endDate: data.dates?.end.toString(),
        lastOrderPreset: data.lastOrderPreset,
        shopId: data.shopId,
        startDate: data.dates?.start.toString(),
      }),
    );
  },

  trackViewedCustomerImportErrorDetails: (
    data: ViewedCustomerImportErrorDetailsEvent,
  ) => {
    analytics.track('viewed_import_error_details', snakeCaseKeys(data));
  },

  trackClickedCustomerImport: (data: ClickedCustomerImportButtonEvent) => {
    analytics.track('clicked_import_button', snakeCaseKeys(data));
  },

  trackClickedOnlineDiscountsTab: (data: OnlineDiscountEvent) => {
    analytics.track('clicked_online_discounts_tab', snakeCaseKeys(data));
  },

  trackClickedPromoCodesTab: (data: PromoCodeEvent) => {
    analytics.track('clicked_promo_codes_tab', snakeCaseKeys(data));
  },

  trackClickedDealsTab: (data: {
    countActiveDeals: number;
    countDeals: number;
    isRegister: boolean;
    page: string;
    shopId: number;
  }) => {
    analytics.track('clicked_deals_tab', snakeCaseKeys(data));
  },

  trackClickedCreateOnlineDiscount: (data: OnlineDiscountEvent) => {
    analytics.track('clicked_create_online_discount', snakeCaseKeys(data));
  },

  trackClickedCreatePromoCode: (data: PromoCodeEvent) => {
    analytics.track('clicked_create_promo_code', snakeCaseKeys(data));
  },

  trackClickedCreateRegisterDiscount: (data: ClickedCreateRegisterDiscount) => {
    analytics.track('clicked_create_register_discount', snakeCaseKeys(data));
  },

  trackClickedScamsInfoLink: (data: {
    isRegister: boolean;
    shopId: string;
  }) => {
    analytics.track('clicked_scams_info_link', snakeCaseKeys(data));
  },

  trackToggledADeal: (data: {
    isRegister: boolean;
    shopId: Shop['shopId'];
    page: string;
    dealName: string;
    dealType: string;
    dealAmount: string;
    toggleAction: 'ON' | 'OFF';
  }) => {
    analytics.track('toggled_a_deal', snakeCaseKeys(data));
  },

  trackTableSettingsClicked(data: { shopId: Shop['shopId'] }) {
    analytics.track('table_settings_clicked', snakeCaseKeys(data));
  },

  trackTableFiltersClicked(data: { shopId: Shop['shopId'] }) {
    analytics.track('table_filters_clicked', snakeCaseKeys(data));
  },

  trackBulkPriceChangeClicked(data: {
    shopId: Shop['shopId'];
    modificationType: 'increase' | 'decrease';
    changeType: 'amount' | 'percentage';
    itemsArr: number[];
    itemsCount: number;
  }) {
    analytics.track('bulk_price_change_clicked', snakeCaseKeys(data));
  },

  trackTableFiltersUpdateClicked(data: {
    shopId: Shop['shopId'];
    filterCriteriaCategoriesSelectedCount: number;
    filterCriteriaOptionsSelectedCount: number;
    categoriesSelectedObj: {
      price: {
        min: number | null;
        max: number | null;
      };
      categories: string[];
      stock: string[];
      printers: string[];
      availability: string[];
    };
  }) {
    analytics.track('table_filters_update_clicked', snakeCaseKeys(data));
  },

  trackTableSettingsUpdateClicked(data: {
    shopId: Shop['shopId'];
    columnsSelectedCount: number;
    columnsSelectedArr: string[];
  }) {
    analytics.track('table_settings_update_clicked', snakeCaseKeys(data));
  },

  trackBusinessProfileViewed(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    bannerText: string;
    googleTotalSales: number;
    googleTotalOrders: number;
    gbpWebsite: string | null;
  }) {
    analytics.track(
      'viewed_gbp_page',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileViewedLogin(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
  }) {
    analytics.track(
      'viewed_gbp_login_page',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileClickedGoogleLogin(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
  }) {
    analytics.track(
      'clicked_log_in_with_google',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileGoogleLoginSuccess(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
  }) {
    analytics.track(
      'logged_in_google',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileViewedAccountLocations(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    locationsCount: number;
    locations: string[];
  }) {
    analytics.track(
      'viewed_select_gpb_location_screen',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileSelectedAccountLocation(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    selection: string;
    isVerified: boolean;
  }) {
    analytics.track(
      'selected_location',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileConfirmedAccountLocation(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    selection: string;
    isVerified: boolean;
  }) {
    analytics.track(
      'clicked_to_confirm_location',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileClickedManage(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    location: ResponseBusinessProfileAccountLocation['location'];
    isVerified: boolean;
  }) {
    analytics.track(
      'clicked_manage_on_google',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileClickedVerify(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    location: ResponseBusinessProfileAccountLocation['location'];
    isVerified: boolean;
  }) {
    analytics.track(
      'clicked_verify_on_google',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileClickedEditWebsite(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    gbpWebsite: string | null;
  }) {
    analytics.track(
      'clicked_to_edit_gbp_website',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackBusinessProfileChangedWebsite(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    gbpWebsite: string | null;
  }) {
    analytics.track(
      'changed_gbp_website',
      snakeCaseKeys({
        ...data,
        page: pageNames.businessProfile,
      }),
    );
  },

  trackDisplayedError(data: {
    shopId: Shop['shopId'];
    isRegister: boolean;
    page: AnalyticsPageName;
    errorText: string;
  }) {
    analytics.track('displayed_error', snakeCaseKeys(data));
  },
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default events;
