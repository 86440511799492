import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  ColumnFiltersState,
  createColumnHelper,
  getFilteredRowModel,
  VisibilityState,
} from '@tanstack/react-table';

import { Badge, Checkbox, IconButton, IconLink } from 'crust';

import { StockDropdown } from 'components/shared/stock-dropdown';
import Table from 'components/shared/table';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useTable } from 'hooks/shared';
import * as paths from 'routes/paths';
import {
  MenuProductsCategory,
  MenuProductsPrinterSetting,
  MenuProductsProduct,
} from 'types/menu/api';
import { Shop } from 'types/shops';
import { nonDisplayCategoryName } from 'utilities/constants';
import { toDollarString, toMoneyString } from 'utilities/currency';

import { useBulkEditContext } from '../context';
import { MenuProductsFilters } from '../filters-modal';

import { ProductImage } from './image';

import styles from './styles.module.scss';

type Props = {
  products: MenuProductsProduct[];
  categories: MenuProductsCategory[];
  shop: Shop;
  menuProductsFilters: MenuProductsFilters;
  columnVisibility: VisibilityState;
  setColumnVisibility: Dispatch<SetStateAction<VisibilityState>>;
};

export type MenuTableRowData = {
  categoryId?: number;
  categoryName?: string;
  id: number;
  image?: string | null;
  isAvailableOffline?: boolean;
  isAvailableOnline?: boolean;
  name: string;
  price?: number;
  unavailable: boolean;
  unavailableUntil: string | null;
  printerSettings: MenuProductsPrinterSetting[];
  subRows?: MenuTableRowData[];
};

export const ProductsTable = ({
  products,
  categories,
  shop,
  menuProductsFilters,
  columnVisibility,
  setColumnVisibility,
}: Props) => {
  const {
    selectedProductIds,
    changeSelectedProductIds,
    clearSelectedProductIds,
  } = useBulkEditContext();

  const data: MenuTableRowData[] = useMemo(
    () =>
      products.map((product) => {
        const productTypesRowData: MenuTableRowData[] = [];
        const category = categories.find(
          (category) => category.id === product.categoryId,
        );

        product.types.forEach((productType) => {
          const { id, name, price, unavailable, unavailableUntil } =
            productType;
          productTypesRowData.push({
            id,
            categoryName: category?.name,
            name,
            price,
            unavailable,
            unavailableUntil,
            printerSettings: product.printerSettings,
          });
        });

        const {
          categoryId,
          id,
          image,
          isAvailableOffline,
          isAvailableOnline,
          name,
          unavailable,
          unavailableUntil,
          printerSettings,
        } = product;

        return {
          categoryId,
          categoryName: category?.name,
          id,
          image,
          isAvailableOffline,
          isAvailableOnline,
          name,
          unavailable,
          unavailableUntil,
          printerSettings,
          subRows: productTypesRowData,
        };
      }),
    [products, categories],
  );

  const columns = useMemo(() => {
    const helper = createColumnHelper<MenuTableRowData>();

    return [
      helper.accessor((row) => row, {
        id: 'collapse',
        header: () => <VisuallyHidden>Expand/Collapse</VisuallyHidden>,
        meta: {
          className: styles.collapseColumn,
        },
        cell: (ctx) => {
          const isExpanded = ctx.row.getIsExpanded();
          const isSubRow = ctx.row.depth > 0;
          const subRows = ctx.row.original.subRows;

          if ((subRows && subRows.length <= 1) || isSubRow) {
            return null;
          }

          return (
            <div className={styles.collapseContainer}>
              <IconButton
                icon={isExpanded ? 'chevronUp' : 'chevronDown'}
                aria-label="Collapse"
                onPress={() => ctx.row.toggleExpanded()}
              />
            </div>
          );
        },
      }),
      helper.accessor('id', {
        id: 'checkbox',
        header: (ctx) => {
          const filteredRows = ctx.table.getFilteredRowModel();
          const productIdsInFilteredRows: number[] = [];

          for (const row of filteredRows.rows) {
            const { id, categoryName } = row.original;

            // We shouldn't allow selection of the non display products
            if (categoryName !== nonDisplayCategoryName) {
              productIdsInFilteredRows.push(id);
            }
          }

          const areAllProductsSelected =
            productIdsInFilteredRows.length > 0 &&
            selectedProductIds.size === productIdsInFilteredRows.length;

          return (
            <div className={styles.checkboxContainer}>
              <VisuallyHidden>Product Selection</VisuallyHidden>
              <Checkbox
                isSelected={areAllProductsSelected}
                aria-label="select all products"
                onChange={(isSelected) => {
                  if (isSelected) {
                    changeSelectedProductIds(
                      productIdsInFilteredRows,
                      isSelected,
                    );
                  } else {
                    clearSelectedProductIds();
                  }
                }}
              />
            </div>
          );
        },
        cell: (ctx) => {
          const { id, name, image, categoryName } = ctx.row.original;
          const isSelected = selectedProductIds.has(id);
          const isSubRow = ctx.row.depth > 0;
          const shouldDisableEditing = categoryName === nonDisplayCategoryName;
          const shouldHideCheckbox = isSubRow || shouldDisableEditing;

          return (
            <div className={styles.checkboxContainer}>
              {shouldHideCheckbox ? (
                <div className={styles.checkboxReplacement} />
              ) : (
                <Checkbox
                  aria-label={`select ${name}`}
                  isSelected={isSelected}
                  onChange={(isSelected) =>
                    changeSelectedProductIds([id], isSelected)
                  }
                />
              )}
              {!isSubRow && <ProductImage imageUrl={image} imageAlt={name} />}
            </div>
          );
        },
      }),
      helper.accessor('name', {
        id: 'name',
        header: 'Name',
        meta: {
          className: styles.nameColumn,
        },
        cell: (ctx) => (
          <Text title={ctx.row.original.name} clamp>
            {ctx.row.original.name}
          </Text>
        ),
      }),
      helper.accessor('price', {
        id: 'price',
        header: 'Price',
        meta: {
          className: styles.priceColumn,
        },

        cell: (ctx) => {
          // if we have a price on the row we have rendered a sub row, so just display the price
          if (ctx.row.original.price) {
            return toMoneyString(toDollarString(ctx.row.original.price));
          }

          // if we are rendering a parent row with only 1 product type, show the price of the single product type
          if (ctx.row.original.subRows?.length === 1) {
            const firstPrice = ctx.row.original.subRows[0]?.price;
            if (firstPrice) {
              return toMoneyString(toDollarString(firstPrice));
            }
          }

          let lowestPrice = 0;

          if (ctx.row.original.subRows) {
            const prices = ctx.row.original.subRows.map(
              (subRow) => subRow.price ?? 0,
            );
            lowestPrice = Math.min(...prices);
          }

          return (
            <Text>{toMoneyString(toDollarString(lowestPrice)) + '+'}</Text>
          );
        },

        filterFn: (row, _, filters) => {
          const { minPrice, maxPrice } = filters;

          if (Number.isNaN(minPrice) && Number.isNaN(maxPrice)) {
            return true;
          }

          if (row.original.subRows === undefined) {
            const price = row.original.price ?? 0;

            return isPriceWithinRange(price, minPrice, maxPrice);
          } else if (row.original.subRows) {
            for (const productType of row.original.subRows) {
              const price = productType.price ?? 0;

              if (isPriceWithinRange(price, minPrice, maxPrice)) {
                return true;
              }
            }

            return false;
          }

          return true;
        },
      }),
      helper.accessor('categoryName', {
        id: 'category',
        header: 'Category',
        cell: (ctx) => {
          return (
            <Text title={ctx.row.original.categoryName} clamp>
              {ctx.row.original.categoryName}
            </Text>
          );
        },
        filterFn: (row, columnId, filters) => {
          if (filters.length === 0) {
            return true;
          }

          const categoryName = row.getValue<string>(columnId);

          return filters.includes(categoryName);
        },
      }),
      helper.accessor(
        (row) => ({
          isAvailableOffline: row.isAvailableOffline,
          isAvailableOnline: row.isAvailableOnline,
        }),
        {
          id: 'availability',
          header: 'Availability',
          meta: {
            className: styles.availabilityColumn,
          },
          cell: (ctx) => {
            const isSubRow = ctx.row.depth > 0;

            if (isSubRow) {
              return null;
            }

            const { isAvailableOffline, isAvailableOnline } = ctx.row.original;

            const availability: string[] = [];

            if (isAvailableOnline) {
              availability.push('Online');
            }

            if (isAvailableOffline) {
              availability.push('Register');
            }

            const availibilityString = availability.join(', ');

            return (
              <Text clamp title={availibilityString}>
                {availibilityString}
              </Text>
            );
          },
          filterFn: (row, columnId, filters) => {
            const isSubRow = row.depth > 0;

            const { isAvailableOnline, isAvailableOffline } = row.getValue<{
              isAvailableOnline: boolean;
              isAvailableOffline: boolean;
            }>(columnId);

            // Ignore filtering by subrow, as it will be the same as in parent row
            // which means if we don't show parent, we won't see subrow
            if (isSubRow || filters.length === 0) {
              return true;
            }

            for (const filter of filters) {
              if (
                (filter === 'online' && isAvailableOnline) ||
                (filter === 'offline' && isAvailableOffline)
              ) {
                return true;
              }
            }

            return false;
          },
        },
      ),
      helper.accessor('printerSettings', {
        id: 'printing',
        header: 'Printing',
        meta: {
          className: styles.nameColumn,
        },

        cell: ({ row }) => {
          const isSubRow = row.depth > 0;
          const printers = row.original.printerSettings
            .filter((it) => it.enabled)
            .map((it) => it.name)
            .join(', ');

          return !isSubRow ? (
            <Text clamp title={printers}>
              {printers}
            </Text>
          ) : null;
        },
        filterFn: (row, columnId, filters) => {
          if (filters.length === 0) {
            return true;
          }

          const printerSettings =
            row.getValue<MenuProductsPrinterSetting[]>(columnId);

          const printersSet = new Set<string>();

          // Collect all enabled printers
          for (const printer of printerSettings) {
            if (printer.enabled) {
              printersSet.add(printer.name);
            }
          }

          // Compare filters with enabled printers
          for (const filter of filters) {
            if (printersSet.has(filter)) {
              return true;
            }
          }

          return false;
        },
      }),
      helper.accessor('unavailable', {
        id: 'stock',
        header: 'Stock',
        meta: {
          className: styles.stockColumn,
        },
        cell: (ctx) => {
          const { id, unavailable } = ctx.row.original;
          const product = products.find((product) => product.id === id);

          if (product) {
            return (
              <StockDropdown
                product={product}
                productTypes={product.types}
                shopId={String(shop.shopId)}
              />
            );
          }

          return unavailable ? (
            <Badge aria-label="product-type-out-of-stock" variant="critical">
              Out of stock
            </Badge>
          ) : (
            <Badge aria-label="product-type-in-stock" variant="success">
              In stock
            </Badge>
          );
        },
        filterFn: (row, columnId, filters) => {
          if (filters.length === 0) {
            return true;
          }

          const unavailable = row.getValue<boolean>(columnId);

          for (const filter of filters) {
            if (
              (filter === 'in-stock' && !unavailable) ||
              (filter === 'out-of-stock' && unavailable)
            ) {
              return true;
            }
          }

          return false;
        },
      }),
      helper.accessor((row) => row, {
        id: 'actions',
        header: () => <VisuallyHidden>Actions</VisuallyHidden>,
        meta: {
          className: styles.actionsColumn,
          isActionColumn: true,
        },
        cell: (ctx) => {
          const { name, id, categoryId } = ctx.row.original;
          if (categoryId) {
            const category = categories.find(
              (category) => category.id === categoryId,
            );

            const shouldAllowEdit =
              category && category.name !== nonDisplayCategoryName;

            if (shouldAllowEdit) {
              return (
                <IconLink
                  icon="pencil"
                  aria-label={`Edit product ${name}`}
                  href={paths.getMenuItemPath(shop.shopId, id)}
                />
              );
            }
          }

          return null;
        },
      }),
    ];
  }, [
    selectedProductIds,
    categories,
    products,
    shop.shopId,
    changeSelectedProductIds,
    clearSelectedProductIds,
  ]);

  const columnFilters: ColumnFiltersState = useMemo(
    () => [
      { id: 'stock', value: menuProductsFilters.stock },
      { id: 'category', value: menuProductsFilters.categories },
      { id: 'availability', value: menuProductsFilters.availability },
      { id: 'printing', value: menuProductsFilters.printers },
      {
        id: 'price',
        value: {
          minPrice: menuProductsFilters.minPrice ?? NaN,
          maxPrice: menuProductsFilters.maxPrice ?? NaN,
        },
      },
    ],
    [menuProductsFilters],
  );

  const isPriceWithinRange = (price: number, min: number, max: number) => {
    if (!Number.isNaN(min) && !Number.isNaN(max)) {
      return price >= min && price <= max;
    } else if (!Number.isNaN(max)) {
      return price <= max;
    } else if (!Number.isNaN(min)) {
      return price >= min;
    }

    // Means we don't have any filters, so any price is withing a range
    return true;
  };

  const table = useTable({
    data,
    chameleonTableTitle: 'Menu Items Table',
    columns,
    getSubRows: (row) => row.subRows,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    defaultColumn: {
      enableSorting: false,
    },
    hasRowStriping: false,
  });

  return <Table className={styles.table} table={table} />;
};
